<template>
    <!-- <div class="w-full [&>*]:px-[24px]"> -->
    <!-- <div class="bg-white sticky top-0 z-30 w-full"> -->
    <Header contentClass="max-w-8xl px-[24px]"></Header>
    <!-- </div> -->


    <main v-bind:attrs="$attrs" id="main"
        :class="['w-full mx-auto mt-44 min-h-[calc(100vh-11rem)] relative bg-slate-10 flex flex-col space-y-10 overflow-x-hidden', props.largeLayout ? '' : 'max-w-8xl px-[24px]', props.mainClass]">
        <transition name="fade" mode="out-in">
            <section class="min-h-full pb-4 flex-1 opacity-1" :key="$page.component">
                <InlineMessage v-if="props.origin" class="!m-auto !mb-8  w-fit !flex" severity="info">{{
                    $t(props.origin)
                    }}
                </InlineMessage>
                <slot name="default"></slot>
            </section>
        </transition>
        <Footer :class="[largeLayout ? 'max-w-8xl px-[24px]' : '']"></Footer>
        <Toast />
        <!-- <Toast position="bottom-left" :pt="{ container: { class: ' !border-transparent' } }"
            :ptOptions="{ mergeProps: true }">
            <template #message="slotProps">
                <div class="flex flex-col items-start" style="flex: 1">
                    <div class="flex gap-x-3">
                        <span class="rounded-[100%] h-6 w-6 bg-green-500 text-white text-center">
                            <i class="pi pi-check text-xs"></i>
                        </span>
                        <H3>{{ slotProps.message.summary }}</H3>
                    </div>
                    <p class="mt-3">{{ slotProps.message.detail }}</p>
                    <Button v-if="slotProps.message.buttonText" :href="slotProps.message.href"
                        :class="['mt-4 text-white', slotProps.message.severity == 'success' ? 'bg-green-500' : '', slotProps.message.severity == 'info' ? 'bg-blue-500' : '', slotProps.message.severity == 'warn' ? 'bg-orange-500' : '', slotProps.message.severity == 'error' ? 'bg-red-500' : '']">
                        {{ slotProps.message.buttonText }}
                    </Button>
                </div>
            </template>
</Toast> -->
        <GA></GA>
    </main>
    <!-- </div> -->
</template>

<script setup>
import Header from "@/Shared/Header.vue";
import Footer from "@/Shared/Footer.vue";
import Toast from "@/Components/Toast.vue"
import { useToast } from "primevue/usetoast";
const toast = useToast();
import GA from "@/Layouts/GA.vue"
import { onUpdated, provide } from 'vue';
import { defineAsyncComponent, watch, ref } from 'vue';
import InputSkeleton from "@/Components/Skeletons/InputSkeleton.vue";
const InlineMessage = defineAsyncComponent({
    loader: () => import("primevue/inlinemessage"),
    loadingComponent: InputSkeleton,
    delay: 0,
})


const props = defineProps({
    messages: Object,
    origin: String,
    largeLayout: {
        type: Boolean,
        default: false

    },
    mainClass: {
        type: String,
        default: null
    }
})


const mainWidthClass = "max-w-8xl px-[24px] w-full mx-auto";
const fullWidthClass = "w-full overflow-hidden";
provide('mainWidthClass', mainWidthClass);
provide('fullWidthClass', fullWidthClass);


// const wClass = props.largeLayout ? ref("") : ref("max-w-8xl overflow-hidden");
// watch(() => props.largeLayout, (value) => {
//     console.log(value, wClass)
//     wClass.value = props.largeLayout ? "" : "max-w-8xl overflow-hidden";

// });



import { trans } from 'laravel-vue-i18n';


onUpdated(() => {
    if (props.messages) {
        if (props.messages.success) {
            toast.add({ severity: 'success', summary: trans('Success'), detail: trans(props.messages.success), life: 5000 });
        }
        else if (props.messages.error) {
            toast.add({ severity: 'error', summary: trans('Error'), detail: trans(props.messages.error), life: 5000 });
        }
        else if (props.messages.info) {
            toast.add({ severity: 'info', summary: trans('Info'), detail: trans(props.messages.info), life: 5000 });
        }
    }
});


</script>

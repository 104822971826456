export const selections_cover = {
    readLater: "background-greenblue-dots",
    "press-auto": "gradient-turquoise",
    "general-business": "gradient-grey",
    tech: "gradient-electricblue",
    Renault: "gradient-amber",
    Peugeot: "gradient-darkblue",
    Dacia: "gradient-turquoise",
    Citroën: "gradient-red",
    Volkswagen: "gradient-lightblue",
    Tesla: "gradient-red",
    Toyota: "gradient-red",
    Ford: "gradient-blue",
    Mercedes: "gradient-cloudgrey",
    BMW: "gradient-blue",
    Audi: "gradient-black",
    Volvo: "gradient-darkblue",
    Fiat: "gradient-red",
    "Alfa Romeo": "gradient-redwine",
    Hyundai: "gradient-cloudgrey",
    Kia: "gradient-red",
    Skoda: "gradient-green",
    Nissan: "gradient-red",
    Opel: "gradient-green-yellow",
    Mini: "gradient-green",
    Seat: "gradient-orange",
    Porsche: "gradient-amber",
    Alpine: "gradient-blue",
    "Land Rover": "gradient-green",
    Smart: "gradient-amber",
};

export const default_selections_cover = [
    "gradient-electricblue",
    "gradient-amber",
    "gradient-darkblue",
    "gradient-indigo",
    "gradient-green-yellow",
    "gradient-red",
    "gradient-lightblue",
    "gradient-cloudgrey",
    "gradient-turquoise",
    "gradient-black",
    "gradient-blue",
    "gradient-green",
    "gradient-redwine",
    "gradient-orange",
];

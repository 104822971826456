<script setup>

</script>

<style>
.emphasize {
    color: rgba(var(--surface-950) / 0.8) !important;
}
</style>


<template>
    <H3 class="!text-normal !text-2xl md:!text-4xl !text-surface-950/50">
        <slot />
    </H3>
</template>
<script setup>
import Checkbox from "@/Components/Checkbox.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import PrimaryButton from "@/Components/Buttons/PrimaryButton.vue";
import TextInput from "@/Components/TextInput.vue";
import Form from "@/Components/Form.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import Password from 'primevue/password';

defineProps({
  canResetPassword: {
    type: Boolean,
  },
  status: {
    type: String,
  },
});

const form = useForm({
  email: "",
  password: "",
  remember: false,
});

const submit = () => {
  form.post(route("login"), {
    onFinish: () => form.reset("password"),
  });
};
</script>

<template>

  <div v-if="status" class="mb-4 font-medium text-base text-green-600">
    {{ status }}
  </div>

  <form @submit.prevent="submit">
    <!-- <H1 class="mb-4">{{ $t('Log In') }}</H1> -->
    <div>
      <InputLabel for="email" :value="$t('Email Address')" />

      <TextInput id="email" type="email" class="mt-1 block w-full" v-model="form.email" required autofocus
        autocomplete="username" />

      <InputError class="mt-2" :message="form.errors.email" />
    </div>

    <div class="mt-4">
      <InputLabel for="password" :value="$t('Password')" />

      <Password id="password" class="mt-1 block w-full !bg-transparent" inputClass="bg-transparent focus:!z-0"
        v-model="form.password" required autocomplete="current-password" toggleMask :feedback="false" />

      <InputError class="mt-2" :message="form.errors.password" />
    </div>

    <div class="block mt-4">
      <label class="flex items-center">
        <Checkbox name="remember" v-model:checked="form.remember" />
        <span class="ms-2 text-base text-gray-600">{{ $t('Remember me') }}</span>
      </label>
    </div>

    <div class="flex items-center justify-end mt-4">
      <Link v-if="canResetPassword" :href="route('password.request')"
        class="underline text-base text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {{ $t('Forgot your password?') }}
      </Link>

      <PrimaryButton class="ms-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        {{ $t('Log In') }}
      </PrimaryButton>
    </div>
  </form>

</template>

<script setup>
import NavLink from "@/Shared/Nav/NavLink.vue";
import { ref } from 'vue';

const props = defineProps({
    text: {
        type: String,
    },
    extraClasses: {
        type: [String, Array],
        default: "",
    },
    dropdowns: {
        type: Array,
    },
});


const visible = ref(false);


</script>


<template>
    <NavLink :text="text" extraClasses="" @click="visible = !visible" class="relative flex flex-col justify-center">
        <slot></slot>
        <template #icon>
            <i class="hidden sm:inline-block pi pi-angle-down ml-2"></i>
        </template>
        <template #footer>
            <Transition name="slideDown">
                <ul v-show="visible"
                    class="hidden sm:inline absolute top-full right-0 w-max p-3 bg-white rounded-md space-y-3 sm:justify-end">
                    <slot name="dropdowns"></slot>
                </ul>
            </Transition>
            <ul class="sm:hidden top-full right-0 w-max mt-3 space-y-3">
                <slot name="dropdowns"></slot>
            </ul>
        </template>

    </NavLink>



</template>
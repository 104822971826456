<script setup>
import TabPanel from 'primevue/tabpanel';
import LoginForm from "@/Pages/Auth/Forms/LoginForm.vue";
import RegisterForm from "@/Pages/Auth/Forms/RegisterForm.vue";

import TabViewSkeleton from "@/Components/Skeletons/TabViewSkeleton.vue";
import { defineAsyncComponent } from 'vue';
const TabView = defineAsyncComponent({
    loader: () => import('primevue/tabview'),
    loadingComponent: TabViewSkeleton,
    // Délai avant l'affichage du composant de chargement. Par défaut : 200 ms.
    delay: 0,

}
);
</script>

<template>

    <Head :title="$t('Login')" />

    <div class="m-auto max-w-192">
        <TabView>
            <TabPanel>
                <template #header>
                    <H5>{{ $t('Login') }}</H5>
                </template>
                <LoginForm :canResetPassword="true"></LoginForm>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <H5>{{ $t('Register') }}</H5>
                </template>
                <RegisterForm :alreadyRegistered="false"></RegisterForm>
            </TabPanel>
        </TabView>
    </div>
</template>
<script setup>



</script>
<style>
.emoji-list li {
    position: relative;
    margin-left: 1.5rem;
}

.emoji-list li::before {
    content: "➡️";
    margin-right: 0.5rem;
    position: absolute;
    left: -1.5rem;
}

.emoji-list ul {
    margin-left: 1.5rem;
}

.emoji-list ul li {
    margin-left: 0px;
}

.emoji-list li li::before {
    content: "👉🏻";
    margin-right: 0.5rem;
    position: absolute;
    left: -1.5rem;
}
</style>

<template>
    <ul class="emoji-list space-y-2">
        <slot></slot>
    </ul>
</template>
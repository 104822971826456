<script setup>
import EmojiList from "@/Components/Lists/EmojiList.vue";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';


const accordionTabPt = {
    headeraction: {
        class: ' bg-white border-0 !text-primary-950 pl-0 hover:bg-inherit'
    },
    header: {
        class: 'sticky top-14 z-[3]'
    },
    content: {
        class: 'border-0 !text-primary-950 pl-0 '
    }
}
</script>

<style scoped>
section>*>h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
}

section>*>h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}
</style>

<template>

    <Head>
        <title>{{ $t('Docs') }}</title>
        <meta name="description"
            :content="$t('Auto Novus aggregates articles on the automotive industry from automotive, general and business press')">
    </Head>

    <H1 class="font-bold mb-10">{{ $t('Docs') }}</h1>

    <div>
        <H2>🎓 Tutorials</H2>
        <p>Découvrez les fonctionnalités clés d'
            <LogoLarge />.
        </p>
        <div class="mt-4">
            <Accordion :multiple="true">
                <AccordionTab :pt="accordionTabPt" :ptOptions="{ mergeProps: false }">
                    <template #header>
                        <H3>Comment filtrer les articles ?</H3>
                    </template>
                    <p class="paragraph ml-5 mb-8">
                    <p>
                        Sur
                        <LogoLarge />, vous pouvez filtrer les articles par mots-clés, date et source. Cela vous permet
                        d'affiner votre suivi de
                        l'actualité automobile.
                    </p>
                    <ol class="flex flex-col gap-y-4 list-inside list-decimal">
                        <li>
                            <span>{{ $t("Click on the filter button") }} ;</span>
                            <img src="/images/tutos/filtres-1.png"
                                class="my-6 max-w-full max-h-screen w-64 object-contain iPhone-shadow-sm" alt="">
                        </li>
                        <li><span>Renseignez vos filtres puis cliquez sur le bouton "Voir les résultats".</span>
                            <img src="/images/tutos/filtres-2.png"
                                class="my-6 max-w-full max-h-screen w-64 object-contain iPhone-shadow-sm" alt="">
                        </li>
                    </ol>
                    </p>
                </AccordionTab>
                <AccordionTab :pt="accordionTabPt" :ptOptions="{ mergeProps: false }">
                    <template #header>
                        <H3>Comment enregistrer les filtres ?</H3>
                    </template>
                    <p class="paragraph ml-5 mb-8">
                    <p>
                        Afin de gagner du temps lors de votre consultation de l'actualité automobile, vous pouvez
                        enregister vos filtres
                        pour les retrouver en quelques clics.
                    </p>
                    <ol class="flex flex-col gap-y-4 list-inside list-decimal">
                        <li>
                            <span>{{ $t("Click on the filter button") }} ;</span>
                            <img src="/images/tutos/filtres-1.png"
                                class="my-6 max-w-full max-h-screen w-64 object-contain iPhone-shadow-sm" alt="">
                        </li>
                        <li><span>Renseignez vos filtres puis cliquez sur le bouton "Sauvegarder les filtres" ;</span>
                            <img src="/images/tutos/filtres-3.png"
                                class="my-6 max-w-full max-h-screen w-64 object-contain iPhone-shadow-sm" alt="">
                        </li>
                        <li><span>Renseignez le nom de votre filtre puis cliquez sur "Sauvegarder" ;</span>

                            <img src="/images/tutos/filtres-4.png"
                                class="my-6 max-w-full max-h-screen w-64 object-contain iPhone-shadow-sm" alt="">
                        </li>
                        <li>Vous pouvez consulter, modifier et supprimer la liste de vos filtres enregistrés dans la
                            page
                            <Link :href="route('view.index')">Mes filtres</Link>.
                        </li>
                    </ol>
                    </p>
                </AccordionTab>
                <AccordionTab :pt="accordionTabPt" :ptOptions="{ mergeProps: false }">
                    <template #header>
                        <H3>Comment ajouter un article à “À lire plus tard” ? </H3>
                    </template>
                    <p class="paragraph ml-5 mb-8">
                    <p>
                        Si un article vous intéresse et que vous ne souhaitez pas le lire immédiatement, vous pouvez
                        l'ajouter à la
                        liste “À lire plus tard”.
                    </p>
                    <ol class="flex flex-col gap-y-4 list-inside list-decimal">
                        <li>
                            <span>Sur une page d'articles, cliquez sur
                                <i
                                    class="pi pi-plus mx-1 h-[15px] w-[15px] content-center text-xs bg-primary-950 text-white text-center rounded"></i>(situé
                                en haut à droite de l'image sur la vue "grille" et au milieu à droite sur la vue
                                "liste") ;
                            </span>
                            <img src="/images/tutos/readLaters-1.png"
                                class="my-6 max-w-full max-h-screen w-64 object-contain iPhone-shadow-sm" alt="">
                        </li>
                        <li><span>Vous pouvez retirer un article de la liste “À lire plus tard” en cliquant sur <i
                                    class="pi pi-minus mx-1 h-[15px] w-[15px] content-center text-xs bg-brown text-white text-center rounded"></i>;</span>
                            <img src="/images/tutos/readLaters-2.png"
                                class="my-6 max-w-full max-h-screen w-64 object-contain iPhone-shadow-sm" alt="">
                        </li>
                        <li>Vous pouvez consulter la liste de vos articles enregistrés dans la
                            page
                            <Link :href="route('readLater.index')">À lire plus tard</Link>
                        </li>
                    </ol>
                    </p>
                </AccordionTab :pt="accordionTabPt" :ptOptions="{ mergeProps: false }">

            </Accordion>



        </div>
    </div>
    <div>
        <H2>🗃️ Versions</H2>
        <EmojiList>
            <li>
                <Link :href="route('docs.v1_4')">V1.4 (2024-06-22)</Link>
            </li>
            <li>
                <Link :href="route('docs.v1_3')">V1.3 (2024-05-29)</Link>
            </li>
            <li>
                <Link :href="route('docs.v1_2')">V1.2 (2024-05-10)</Link>
            </li>
        </EmojiList>
    </div>


</template>
<script setup>
const props = defineProps({
    name: String,
    url: String,
    data_key: String,
    data_index: {
        type: Number,
        default: 0
    }

})
import { selections_cover, default_selections_cover } from "@/Styles/selections_cover.js"
function getBgClass(data_key, data_index) {
    if (data_key in selections_cover) {
        return selections_cover[data_key];
    } else {
        if (data_index < default_selections_cover.length) {
            return default_selections_cover[data_index];
        }
        else {
            return default_selections_cover[data_index % default_selections_cover.length];
        }
    }
}
</script>


<template>
    <component :is="url ? 'Link' : 'div'" :href="url"
        :class="[getBgClass(data_key, data_index), 'rounded-md aspect-square relative hover:opacity-75 transition-opacity duration-300 text-white p-3']">
        <H1 class="text-base sm:text-lg  p-[5%] absolute left-0 bottom-0 corner-cut-red">{{ $t(props.name) }}</H1>
    </component>
</template>
<script setup>
import ArticleFeed from "@/Components/Feed/ArticlesFeed.vue";
import SecondaryButton from "@/Components/Buttons/SecondaryButton.vue";
import SelectionCover from '@/Components/Selection/SelectionCover.vue';

const props = defineProps({
    articles: Object,
});
</script>


<template>

    <Head>
        <title>{{ $t('Read later') }}</title>
    </Head>
    <div class="w-full flex flex-col gap-y-10">

        <div>
            <H1 class="text-xl font-bold">{{ $t('Read later') }}</h1>
        </div>
        <SelectionCover data_key="readLater" name="Read later" class='w-72 block' />
        <div>

            <ArticleFeed :articles="props.articles" :filters="[]" :showFilters="false" :paginator="true" />
            <SecondaryButton v-if="props.articles.data.length == 0" id="read-later-demo-docs-button"
                :href="route('docs')" class="w-fit h-10 mt-16 align-middle">
                Découvrir comment ajouter un article à "À lire
                plus tard"<i class="ml-2 pi pi-arrow-right">
                </i></SecondaryButton>
        </div>

    </div>

</template>
import global from "./global.js";
import inputswitch from "./inputswitch";
import accordion from "./accordion";
import calendar from "./calendar";
import checkbox from "./checkbox";
import carousel from "./carousel";
import chips from "./chips";
import chip from "./chip";
import datatable from "./datatable";
import dataview from "./dataview";
import dialog from "./dialog";
import divider from "./divider";
import inlinemessage from "./inlinemessage";
import inputtext from "./inputtext";
import multiselect from "./multiselect";
import paginator from "./paginator";
import password from "./password";
import sidebar from "./sidebar";
import skeleton from "./skeleton";
import tabview from "./tabview";
import textarea from "./textarea";
import toast from "./toast";
import selectbutton from "./selectbutton";
import dropdown from "./dropdown";
import overlaypanel from "./overlaypanel";

export default {
    global,
    inputswitch,
    accordion,
    calendar,
    checkbox,
    carousel,
    chips,
    chip,
    datatable,
    dataview,
    dialog,
    divider,
    inlinemessage,
    inputtext,
    multiselect,
    paginator,
    password,
    sidebar,
    skeleton,
    tabview,
    textarea,
    toast,
    selectbutton,
    dropdown,
    overlaypanel,
};

<script setup>
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import Password from 'primevue/password';

import { useForm } from '@inertiajs/vue3';
import { ref } from 'vue';

const passwordInput = ref(null);
const currentPasswordInput = ref(null);

const form = useForm({
    current_password: '',
    password: '',
    password_confirmation: '',
});

const updatePassword = () => {
    form.put(route('password.update'), {
        preserveScroll: true,
        onSuccess: () => form.reset(),
        onError: () => {
            if (form.errors.password) {
                form.reset('password', 'password_confirmation');
                passwordInput.value.focus();
            }
            if (form.errors.current_password) {
                form.reset('current_password');
                currentPasswordInput.value.focus();
            }
        },
    });
};
</script>

<template>
    <section>
        <header>
            <H3 class="text-lg font-medium text-gray-900">{{ $t('Update Password') }}</H3>

            <p class="mt-1 text-base text-gray-600">
                {{
                $t('Ensure your account is using a long, random password to stay secure.') }}
            </p>
        </header>

        <form @submit.prevent="updatePassword" class="mt-6 space-y-6">
            <div>
                <InputLabel for="current_password" :value="$t('Current Password')" />

                <Password id="current_password" ref="currentPasswordInput" v-model="form.current_password"
                    class="mt-1 block w-full !bg-transparent" inputClass="bg-transparent focus:!z-0" toggleMask
                    :feedback="false" autocomplete="current-password" />


                <InputError :message="form.errors.current_password" class="mt-2" />
            </div>

            <div>
                <InputLabel for="password" :value="$t('New Password')" />

                <Password id="password" ref="passwordInput" v-model="form.password"
                    class="mt-1 block w-full !bg-transparent" inputClass="bg-transparent focus:!z-0" toggleMask
                    autocomplete="new-password" />

                <InputError :message="form.errors.password" class="mt-2" />
            </div>

            <div>
                <InputLabel for="password_confirmation" :value="$t('Confirm Password')" />

                <Password id="password_confirmation" v-model="form.password_confirmation"
                    tclass="mt-1 block w-full !bg-transparent" inputClass="bg-transparent focus:!z-0" toggleMask
                    :feedback="false" autocomplete="new-password" />

                <InputError :message="form.errors.password_confirmation" class="mt-2" />
            </div>

            <div class="flex items-center gap-4">
                <PrimaryButton :disabled="form.processing">{{ $t('Save') }}</PrimaryButton>

                <Transition enter-active-class="transition ease-in-out" enter-from-class="opacity-0"
                    leave-active-class="transition ease-in-out" leave-to-class="opacity-0">
                    <p v-if="form.recentlySuccessful" class="text-base text-gray-600">{{ $t('Saved.') }}</p>
                </Transition>
            </div>
        </form>
    </section>
</template>

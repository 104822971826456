<script setup>
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import Form from '@/Components/Form.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, useForm } from '@inertiajs/vue3';
import Password from 'primevue/password';
const props = defineProps({
    email: {
        type: String,
        required: true,
    },
    token: {
        type: String,
        required: true,
    },
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.store'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>

    <Head :title="$t('Reset Password')" />
    <Form class="max-w-128">
        <H1 class="mb-6">{{ $t("Reset Password") }}</H1>
        <form @submit.prevent="submit">
            <div>
                <InputLabel for="email" :value="$t('Email Address')" />

                <TextInput id="email" type="email" class="mt-1 block w-full" v-model="form.email" required autofocus
                    autocomplete="username" />

                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="mt-4">
                <InputLabel for="password" :value="$t('Password')" />

                <Password id="password" class="mt-1 block w-full !bg-transparent" inputClass="bg-transparent focus:!z-0"
                    v-model="form.password" required autocomplete="new-password" toggleMask />

                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="mt-4">
                <InputLabel for="password_confirmation" :value="$t('Confirm Password')" />

                <Password id="password_confirmation" class="mt-1 block w-full !bg-transparent"
                    inputClass="bg-transparent focus:!z-0" v-model="form.password_confirmation" required
                    autocomplete="new-password" toggleMask :feedback="false" />

                <InputError class="mt-2" :message="form.errors.password_confirmation" />
            </div>

            <div class="flex items-center justify-end mt-4">
                <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    {{ $t('Reset Password') }}
                </PrimaryButton>
            </div>
        </form>
    </Form>
</template>

<script setup>
import { ref, defineAsyncComponent } from 'vue';
import Checkbox from 'primevue/checkbox';
import Button from "@/Components/Buttons/Button.vue";
import PrimaryButton from "@/Components/Buttons/PrimaryButton.vue";
import SecondaryButton from '@/Components/Buttons/SecondaryButton.vue';
import Sidebar from 'primevue/sidebar';



// Consent mode
window.dataLayer = window.dataLayer || [];

function gtag() {
    dataLayer.push(arguments);
}
if (localStorage.getItem('consentMode') === null) {
    gtag('consent', 'default', {
        'ad_storage': 'denied',
        'analytics_storage': 'granted',
        'personalization_storage': 'granted',
        'functionality_storage': 'granted',
        'security_storage': 'granted',
    });
} else {
    gtag('consent', 'default', JSON.parse(localStorage.getItem('consentMode')));
}
if (localStorage.getItem('userId') != null) {
    window.dataLayer.push({
        'user_id': localStorage.getItem('userId')
    });
}

// Manage Consent vanner
const showBanner = ref(false);
function hideBanner() {
    showBanner.value = false;
}
const necessary = ref(true);
const analytics = ref(true);
const preferences = ref(false);
const marketing = ref(false);

if (localStorage.getItem('consentMode') === null) {
    setTimeout(() => {
        showBanner.value = true
    }, 10000);
}
function acceptAll() {
    setConsent({
        necessary: true,
        analytics: true,
        preferences: true,
        marketing: true
    });
    hideBanner();
}

function acceptSome() {
    setConsent({
        necessary: true,
        analytics: analytics.value,
        preferences: preferences.value,
        marketing: marketing.true
    });
    hideBanner();
}

function rejectAll() {
    setConsent({
        necessary: false,
        analytics: false,
        preferences: false,
        marketing: false
    });
    hideBanner();

}

function setConsent(consent) {
    const consentMode = {
        'functionality_storage': consent.necessary ? 'granted' : 'denied',
        'security_storage': consent.necessary ? 'granted' : 'denied',
        'ad_storage': consent.marketing ? 'granted' : 'denied',
        'analytics_storage': consent.analytics ? 'granted' : 'denied',
        'personalization_storage': consent.preferences ? 'granted' : 'denied',
    };
    gtag('consent', 'update', consentMode);
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
}

// Set GA 
(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-WJGDT862');

</script>


<template>
    <Transition name="slideUp">
        <div v-if="false" id="cookie-consent-banner" class="bg-white border-t border-surface-200 flex-col space-y-6"
            v-bind:class="$attrs.class">
            <div class="relative">
                <button @click="acceptSome" class="absolute right-0 top-0 p-2 -m-2"><i class="pi pi-times"></i></button>
                <H3>{{ $t('Cookie settings') }}</H3>
                <Description class="mt-2">
                    {{
                        // prettier-ignore
                        $t("Cookies description")
                    }}
                </Description>
            </div>
            <div class="flex space-x-4">
                <Description>
                    <Checkbox id="consent-necessary" v-model="necessary" :binary="true" disabled class="mr-2" />
                    {{ $t("Necessary") }}
                </Description>
                <Description>
                    <Checkbox id="consent-analytics" v-model="analytics" :binary="true" class="mr-2" />
                    {{ $t("Analytics") }}
                </Description>
                <Description>
                    <Checkbox id="consent-preferences" v-model="preferences" :binary="true" class="mr-2" />
                    {{ $t("Preferences") }}
                </Description>
                <Description>
                    <Checkbox id="consent-marketing" v-model="marketing" :binary="true" class="mr-2" />
                    {{ $t("Marketing") }}
                </Description>
            </div>
            <div class="flex space-x-4">
                <Button id="btn-reject-all" @click="rejectAll" class="border-brown border-2 text-brown">
                    {{ $t("Reject all") }}
                </Button>
                <SecondaryButton id="btn-accept-some" @click="acceptSome" class="">
                    {{ $t("Accept selection") }}</SecondaryButton>
                <PrimaryButton id="btn-accept-all" @click="acceptAll" class="">{{ $t("Accept all") }}
                </PrimaryButton>
            </div>
        </div>
    </Transition>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WJGDT862" height="0" width="0"
            style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
</template>
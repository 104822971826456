<template>
    <div
        :class="['sticky top-14 z-10 py-6 flex flex-wrap-reverse items-stretch justify-between gap-4 bg-white bg-w-full']">
        <NumberResult :nb_results="articlesNb" class="hidden sm:flex min-w-fit grow-0 whitespace-nowrap" />
        <span class="hidden sm:flex grow shrink-1"></span>
        <GridListToggle :layout="layout" class="grow-0" @update:modelValue="(value) => updateLayout(value)" />
        <ArticleFilter v-if="props.showFilters" :filters="filters" class="grow-0" />
    </div>
    <ArticlesGrid :articles="articles.data" :filters="filters" :layout="layout" class="mt-12" />
    <Paginator v-if="paginator && articlesNb > 0" :articles="articles" />
</template>

<script setup>
import Paginator from "@/Components/Paginator/Paginator.vue";
import NumberResult from "@/Components/Feed/NumberResult.vue";
import ArticleFilter from "@/Components/Feed/Filters/ArticleFilter.vue";
import GridListToggle from "@/Components/Feed/GridListToggle.vue";
import { provide, computed, ref, inject } from "vue";
const props = defineProps({
    articles: Object,
    filters: Object,
    showFilters: { default: true },
    paginator: {
        type: Boolean,
        default: true
    }
});
const mainWidthClass = inject("mainWidthClass");

const layout = ref(localStorage.getItem('layout') || 'grid');
const updateLayout = (value) => {
    layout.value = value;
    window.localStorage.setItem('layout', value);
};

import { defineAsyncComponent } from 'vue';
import GridSkeleton from "@/Components/Skeletons/GridSkeleton.vue";
import ListSkeleton from "@/Components/Skeletons/ListSkeleton.vue";

const ArticlesGrid = defineAsyncComponent({
    loader: () => import("@/Components/Feed/ArticlesGrid.vue"),
    loadingComponent: layout.value == "grid" ? GridSkeleton : ListSkeleton,
    delay: 0,
}
);



const articlesNb = computed(() => props.articles.total);
provide('articlesNb', articlesNb);


</script>
<template>
  <nav class="w-full flex items-center justify-center mt-16">
    <ul class="w-full flex items-center justify-between gap-x-8 border-t border-gray-200">
      <template v-if="props.articles.current_page != 1">
        <li class="hidden sm:flex w-32 justify-start">
          <PaginatorLink v-if="props.articles.first_page_url" :href="props.articles.first_page_url">
            <LeftArrow />{{ $t("First") }}
          </PaginatorLink>
        </li>
        <li class="sm:hidden w-32 flex justify-start">
          <PaginatorLink v-if="props.articles.prev_page_url" :href="props.articles.prev_page_url">
            <LeftArrow />{{ $t("Previous") }}
          </PaginatorLink>
        </li>
      </template>
      <li v-else class="w-32"></li>


      <li class="flex gap-x-4">
        <template v-for="(link, index) in  links" :key="link.key">
          <PaginatorLink :href="link.url" :active="link.active" :class="link.active ? 'flex' : 'hidden sm:flex'">
            {{ link.label }}
          </PaginatorLink>
        </template>
      </li>



      <template v-if="props.articles.current_page != props.articles.last_page">
        <li class="hidden sm:flex w-32 justify-end">
          <PaginatorLink v-if="props.articles.last_page_url" :href="props.articles.last_page_url">
            {{ $t("Last") }}
            <RightArrow />
          </PaginatorLink>
        </li>
        <li class="sm:hidden flex w-32 justify-start">
          <PaginatorLink v-if="props.articles.next_page_url" :href="props.articles.next_page_url">
            {{ $t("Next") }}
            <RightArrow />
          </PaginatorLink>
        </li>
      </template>
      <li v-else class="w-32"></li>
    </ul>
  </nav>
</template>


<script setup>
import { computed } from "vue";
import LeftArrow from "../Icons/LeftArrow.vue";
import RightArrow from "../Icons/RightArrow.vue";
import PaginatorLink from "./PaginatorLink.vue";

const props = defineProps({
  articles: Object,
});


const links = computed(() => {
  let links = props.articles.links.slice(1, -1);
  let activeIndex = links.findIndex(item => item.active === true);
  // Si l'index actif est proche du début du tableau
  if (activeIndex < 3) {
    return links.slice(0, 5);
  }
  // Si l'index actif est proche de la fin du tableau
  else if (activeIndex > links.length - 3) {
    return links.slice(links.length - 5);
  }
  // Pour tous les autres cas
  else {
    return links.slice(activeIndex - 2, activeIndex + 3);
  }
});
</script>
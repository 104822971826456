<template>
    <div class="card flex justify-content-center rounded-md overflow-hidden bg-surface-100 !p-1">
        <button v-for="(option, index) in options" @click="layout = option.value"
            class="rounded-none py-2 px-4 hover:opacity-75 transition-colors duration-100"
            :class="[option.value == layout ? 'bg-white' : 'bg-transparent', index == 0 ? 'rounded-l-md' : '', index == options.length - 1 ? 'rounded-r-lg' : '']">
            <i :class="option.icon"></i>
        </button>

        <!-- <SelectButton v-model="layout" :options="options" optionLabel="value" optionValue="value" dataKey="value"
            aria-labelledby="custom" pt:button:class="!h-full" :ptOptions="{ mergeProps: true }">
            <template #option="slotProps">
                <i :class="slotProps.option.icon"></i>
            </template>
</SelectButton> -->
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
const props = defineProps({
    layout: String,
});
const layout = ref(props.layout);
const options = ref([
    { icon: 'pi pi-th-large', value: 'grid' },
    { icon: 'pi pi-bars', value: 'list' },]);

const emit = defineEmits(['update:modelValue']);
watch(layout, (newVal) => {
    emit('update:modelValue', newVal);
});
</script>
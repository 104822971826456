<template>

    <Head>
        <title>{{ $t('Edit my filters') }}</title>
    </Head>
    <div class="w-full m-auto max-w-192 p-8 rounded-lg">
        <div>
            <H1 class="text-xl font-bold mb-10">{{ $t('Edit my filter') }}</H1>
        </div>
        <FiltersForm ref="filtersForm" :filters="view" :viewName="true" class="">

            <template #buttons>
                <PrimaryButton type="button" @click="saveFilters" class="w-full h-14">
                    {{ $t('Save filters') }}</PrimaryButton>

            </template>
        </FiltersForm>
    </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue';
const FiltersForm = defineAsyncComponent(() =>
    import('@/Components/Feed/Filters/FiltersForm.vue')
);
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue'
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'
const props = defineProps(
    {
        view: Object,
    }
)

const filtersForm = ref(null)
const saveFilters = () => {
    filtersForm.value.saveFilters(() => {
        router.get(route("view.index"), {}, { preserveState: true, preserveScroll: true, replace: true });

    });

};

</script>
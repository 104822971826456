<script setup>
import PrimaryButton from "@/Components/Buttons/PrimaryButton.vue";
import { useForm } from '@inertiajs/vue3'
import { router, usePage } from "@inertiajs/vue3";
import { defineAsyncComponent } from 'vue';
import InputSkeleton from "@/Components/Skeletons/InputSkeleton.vue";
const InputText = defineAsyncComponent({
    loader: () => import("primevue/inputtext"),
    loadingComponent: InputSkeleton,
    delay: 0,
})
const Textarea = defineAsyncComponent({
    loader: () => import('primevue/textarea'),
    loadingComponent: InputSkeleton,
    delay: 0,
})
const InlineMessage = defineAsyncComponent({
    loader: () => import('primevue/inlinemessage'),
})

const props = defineProps({
    errors: Object
})


const form = useForm({
    name: usePage().props.auth.user ? usePage().props.auth.user.name : null,
    email: usePage().props.auth.user ? usePage().props.auth.user.email : null,
    mail_subject: null,
    message: null,
})
function submit() {
    router.post(route("contact.send"), form, {
        onSuccess: () => {
            form.reset();
        }
    })
}
</script>


<template>
    <div class="m-auto max-w-192 flex flex-col gap-y-6">
        <H1>{{ $t("Contact us") }}</H1>
        <form @submit.prevent="submit" class="flex flex-col gap-y-4 justify-stretch">
            <span>
                <H5 class="mb-1" for="name">{{ $t("Name") }}</H5>
                <InputText class="w-full" id="name" v-model="form.name" />
                <InlineMessage v-if="errors.name" severity="error" class="mt-1">{{ errors.name }}</InlineMessage>

            </span>
            <span>
                <H5 class="mb-1" for="email">{{ $t("Email") }}</H5>
                <InputText class="w-full" id="email" v-model="form.email" />
                <InlineMessage v-if="errors.email" severity="error" class="mt-1">{{ errors.email }}</InlineMessage>
            </span>
            <span>
                <H5 class="mb-1" for="mail_subject">{{ $t("Subject") }}</H5>
                <InputText class="w-full" id="mail_subject" v-model="form.mail_subject" />
                <InlineMessage v-if="errors.mail_subject" severity="error" class="mt-1">{{ errors.mail_subject }}
                </InlineMessage>

            </span>
            <span>
                <H5 class="mb-1" for="message">{{ $t("Message") }}</H5>
                <Textarea class="w-full" id="message" v-model="form.message" rows="10" />
                <InlineMessage v-if="errors.message" severity="error" class="mt-1">{{ errors.message }}</InlineMessage>

            </span>


            <PrimaryButton class="w-fit" type="submit">{{ $t("Envoyer") }}</PrimaryButton>
        </form>
    </div>
</template>
<script setup>
import Divider from "primevue/divider";
import Carousel from 'primevue/carousel';
import SelectionCover from '@/Components/Selection/SelectionCover.vue';
import { ref } from "vue"
const props = defineProps({
    title: {
        type: String,
        required: true
    },
    elements: {
        type: Object,
        required: true
    }
});

import { objToArr } from "@/main"
const elements_arr = objToArr(props.elements);



const responsiveOptions = ref([
    {
        breakpoint: '14000px',
        numVisible: 4,
        numScroll: 4
    }, {
        // xs
        breakpoint: '574px',
        numVisible: 3,
        numScroll: 3
    }, {
        // xxs
        breakpoint: '399px',
        numVisible: 2,
        numScroll: 2
    }
]);

const buttonVisibility = (elements) => {
    if (elements.length <= 2) {
        return 'invisible';
    } else if (elements.length == 3) {
        return 'visible xxs:invisible';
    } else if (elements.length == 4) {
        return 'visible xxs:visible xs:invisible';
    } else {
        return 'visible';
    }
}

</script>


<template>
    <div>
        <Divider></Divider>
        <H3 class="mb-4">{{ props.title }}</H3>
        <div class="card">
            <Carousel ref="carousel" :value="elements_arr" :numVisible="3" :numScroll="3"
                :responsiveOptions="responsiveOptions" circular :showIndicators="false" class="-mx-10 md:-mx-12"
                containerClass="relative"
                :pt:previousButton:class="['text-[10px] -mr-2 md:-mr-0', buttonVisibility(elements_arr)]"
                :pt:nextButton:class="['text-[10px] -ml-2 md:-ml-0', buttonVisibility(elements_arr)]"
                pt:item:class="!grow-0" :ptOptions="{ mergeProps: true }">
                <template #item="slotProps">
                    <SelectionCover :data_key="slotProps.data.key" :data_index="slotProps.index"
                        :name="slotProps.data.name" :url="slotProps.data.url" class='w-full mx-2' />
                </template>
            </Carousel>
        </div>
    </div>
</template>
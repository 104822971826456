<script setup>
import Form from "@/Components/Form.vue";
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';

import TextInput from '@/Components/TextInput.vue';
import { Head, useForm } from '@inertiajs/vue3';

defineProps({
    status: {
        type: String,
    },
});

const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('password.email'));
};
</script>

<template>

    <Head :title="$t('Forgot Password')" />
    <Form class="max-w-128">
        <H1 class="mb-6">{{ $t("Forgot Password") }}</H1>

        <div class="mb-4 text-base text-gray-600">
            {{ $t("password_reset_message") }}
        </div>

        <div v-if="status" class="mb-4 font-medium text-base text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel for="email" :value="$t('Email Address')" />

                <TextInput id="email" type="email" class="mt-1 block w-full" v-model="form.email" required autofocus
                    autocomplete="username" />

                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="flex items-center justify-end mt-4">
                <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    {{ $t('Email Password Reset Link') }}
                </PrimaryButton>
            </div>
        </form>
    </Form>
</template>

<script setup>
import { computed } from 'vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import Button from '@/Components/Buttons/Button.vue';

import { Head, Link, useForm } from '@inertiajs/vue3';

const props = defineProps({
    status: {
        type: String,
    },
});

const form = useForm({});

const submit = () => {
    form.post(route('verification.send'));
};

const verificationLinkSent = computed(() => props.status === 'verification-link-sent');
</script>

<template>

    <Head :title="$t('Email Verification')" />
    <H1 v-if="$page.props.redirect" class="mb-6">
        {{ $t("Before accessing to this page, please verify your email address") }}
    </H1>
    <H1 v-else class="mb-6">{{ $t("Email Verification") }}</H1>
    <div class="mb-4 text-base text-gray-600">
        {{ $t("pleaseOpenLinkSentToVerifyAccount") }}
    </div>

    <div class="mb-4 font-medium text-base text-green-600" v-if="verificationLinkSent">
        {{ $t('A new verification link has been sent to the email address you provided during registration.') }}
    </div>

    <form @submit.prevent="submit">
        <div class="mt-8 flex items-center">
            <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                {{ $t('Resend Verification Email') }}
            </PrimaryButton>

            <Button :href="route('logout')" method="post" as="button" class="">
                {{ $t('Log Out') }}</Button>
        </div>
    </form>
</template>

<template>
    <!-- Delete filters button -->
    <Transition enter-active-class="transition duration-250 ease-linear transform" enter-from-class="opacity-0"
        enter-to-class="opacity-1" leave-active-class="transition duration-250 ease-linear opacity"
        leave-from-class="opacity-1" leave-to-class="opacity-0">
        <Button v-if="props.filters && Object.keys(props.filters).length" @click="router.get(route($page.props.route.name, $page.props.route.parameters), {}, {
            preserveState: true,
            preserveScroll: true, replace: true
        });" v-bind="$attrs" class="hidden sm:flex bg-brown text-white">{{
            $t("Delete filters") }}</Button>
    </Transition>

    <!-- Open filters panel button -->
    <PrimaryButton @click="sidePanelVisibility = !sidePanelVisibility" v-bind="$attrs"
        class="filters-open-button items-center">{{
            $t("Filters")
        }}<template v-if="filtersCount > 0"> ({{ filtersCount }})</template>
        <Filter class="h-6 inline-block ml-2" />
    </PrimaryButton>

    <!-- Filters panel -->
    <Sidebar v-model:visible="sidePanelVisibility" position="right" :showCloseIcon="false" :blockScroll="true"
        class="w-screen sm:w-128" pt:header:class="p-2.5">
        <FiltersForm ref="filtersForm" @update:form="(value) => applyFilters(value)" :filters="filters"
            :live-filtering="true">
            <template #header>
                <div class="flex justify-between">
                    <H3><span class="flex">{{ $t("Filters") }} <p class="text-red-600 super">({{ filtersCount }})
                            </p>
                        </span>
                    </H3>
                    <button @click="sidePanelVisibility = false" class="cursor-pointer p-4 -m-4">
                        <i class="pi pi-times"></i>
                    </button>
                </div>
                <Divider />
            </template>
            <template #buttons>
                <PrimaryButton type="button" @click="sidePanelVisibility = false" class="w-full h-14" pClass="">
                    {{ `${$t("See results")} (${articlesNb})` }}
                </PrimaryButton>
                <Transition name="fade">

                    <SecondaryButton @click="openFilterNameInput()" type="button" v-if="filtersCount > 0"
                        class="w-full h-14">
                        {{ $t("Save filters") }}
                    </SecondaryButton>
                </Transition>
                <Button type="button" @click="clearFilters()" class="w-full h-14 border-brown border-2 text-brown"
                    pClass="">
                    {{ $t('Delete filters') }}
                </Button>
            </template>
        </FiltersForm>
    </Sidebar>




</template>

<script setup>
import PrimaryButton from "@/Components/Buttons/PrimaryButton.vue";
import SecondaryButton from '@/Components/Buttons/SecondaryButton.vue';
import { defineAsyncComponent } from 'vue';
const FiltersForm = defineAsyncComponent(() =>
    import('@/Components/Feed/Filters/FiltersForm.vue')
)
import Button from "@/Components/Buttons/Button.vue";
import Filter from "@/Components/Icons/Filter.vue";

import Divider from "primevue/divider";
import Sidebar from 'primevue/sidebar';

import { ref, inject, computed, watch } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import { calObjNbElem } from "@/main";


const props = defineProps({
    filters: Object,
    message: {
        type: String,
        default: null
    }
});

// Manage sidePanel
const sidePanelVisibility = ref(false);


// Manage filters
const filtersForm = ref(null);
const clearFilters = () => filtersForm.value.clearFilters();
const applyFilters = () => filtersForm.value.applyFilters();
const setViewNameDialog = (state) => filtersForm.value.setViewNameDialog(state);

const openFilterNameInput = () => {
    if (usePage().props.auth.user) {
        if (usePage().props.auth.user.email_verified_at != null) {
            setViewNameDialog(true);
        }
        else {
            router.get(route('verification.notice'), { origin: btoa("You must verify your email to register your filters") });
        }
    }
    else {
        router.get(route('auth'), { origin: btoa("You need to be logged in to register your filters") })
    }

}
const filtersCount = computed(() => calObjNbElem(props.filters));

const articlesNb = inject('articlesNb');





</script>

export function calObjNbElem(obj) {
    return Object.values(obj).reduce((total, current) => {
        if (current) {
            if (Array.isArray(current)) {
                return total + current.length;
            } else {
                return total + 1;
            }
        } else {
            return total;
        }
    }, 0);
}

// import moment from "moment";
// export function formatHRD(date) {
//     date = strToDate(date);
//     if (date) {
//         return moment(date).fromNow();
//     } else {
//         return null;
//     }
// }

export function strToDate(str) {
    return typeof str === "string" ? new Date(str) : str;
}

export const highlightText = (text, search = [], title = "") => {
    let highlightedText = text;
    if (search) {
        search.forEach((term) => {
            const regex = new RegExp("(" + term + ")", "gi");
            highlightedText = highlightedText.replace(
                regex,
                '<span class="bg-amber-200">$1</span>'
            );
        });
    }
    if (title) {
        const regex = new RegExp("(" + title + ")", "gi");
        highlightedText = highlightedText.replace(
            regex,
            '<span class="bg-lime-400">$1</span>'
        );
    }
    return highlightedText;
};

export function objToArr(object, keyName = "key") {
    return Object.keys(object).map((key) => ({
        [keyName]: key,
        ...object[key],
    }));
}

export function parseBool(str) {
    if (typeof str === "boolean") return str;
    return str.toLowerCase() === "true";
}

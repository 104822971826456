<template>

    <Head>
        <title>{{ $t('My filters') }}</title>
    </Head>
    <div>
        <H1 class="text-xl font-bold mb-10">{{ $t('My filters') }}</h1>
    </div>
    <ViewsList :views="views" />
</template>

<script setup>
import { defineAsyncComponent } from 'vue';
import ViewsSkeleton from "@/Components/Skeletons/ViewsSkeleton.vue";
const ViewsList = defineAsyncComponent({
    loader: () => import('@/Components/Views/ViewsList.vue'),
    loadingComponent: ViewsSkeleton,
    // Délai avant l'affichage du composant de chargement. Par défaut : 200 ms.
    delay: 0,

}
);
const props = defineProps(
    {
        views: Object,
    }
)
</script>
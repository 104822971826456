<template>
  <NavBar class="hidden sm:inline-block" />
  <div class="sm:hidden">
    <Sidebar v-model:visible="visible" position="right" :header="$t('Menu')">
      <NavBar @click="visible = false" />
    </Sidebar>
    <button class="" @click="visible = true">
      <i class="pi pi-bars -m-4 p-4"></i>
    </button>
  </div>

</template>


<script setup>
import Sidebar from 'primevue/sidebar';
import NavBar from '@/Shared/Nav/NavBar.vue';
import { ref, watchEffect } from "vue";

const visible = ref(false);



</script>

import "./bootstrap";
import "../css/app.css";
// import "flowbite";
import { createApp, h, ref } from "vue";
import { createInertiaApp, Link, Head } from "@inertiajs/vue3";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import Layout from "./Layouts/Layout.vue";
import { i18nVue } from "laravel-vue-i18n";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import ConfirmationService from "primevue/confirmationservice";
import AnimateOnScroll from "primevue/animateonscroll";
import H1 from "@/Components/Headings/H1.vue";
import H2 from "@/Components/Headings/H2.vue";
import H3 from "@/Components/Headings/H3.vue";
import H4 from "@/Components/Headings/H4.vue";
import H5 from "@/Components/Headings/H5.vue";
import Description from "@/Components/Headings/Description.vue";
import LogoLarge from "@/Components/Headings/LogoLarge.vue";
import Lara from "@/Presets/Lara";

// const appName = import.meta.env.VITE_APP_NAME || "Auto Novus";

import fr from "/lang/pv_fr.json";
const primeVueLocale = ref(fr);

// Surveiller les changements de langue dans i18nVue
// watch(
//     () => i18nVue.locale,
//     (newLocale) => {
//         // Charger le fichier de traduction correspondant de PrimeVue
//         if (newLocale === "fr") {
//             primeVueLocale.value = fr;
//         } else {
//             primeVueLocale.value = en;
//         }
//     }
// );

createInertiaApp({
    title: (title) => (title ? `${title} - Auto Novus` : `Auto Novus`),

    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        let page = pages[`./Pages/${name}.vue`];
        page.default.layout = page.default.layout || Layout;
        return page;
    },

    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(PrimeVue, {
                styled: true,
                pt: Lara,
                locale: primeVueLocale,
            })
            .component("Link", Link)
            .component("Head", Head)
            .component("H1", H1)
            .component("H2", H2)
            .component("H3", H3)
            .component("H4", H3)
            .component("H5", H5)
            .component("Description", Description)
            .component("LogoLarge", LogoLarge)
            .use(ConfirmationService)
            .use(ToastService)
            .use(i18nVue, {
                resolve: async (lang) => {
                    const langs = import.meta.glob("../../lang/*.json");
                    return await langs[`../../lang/${lang}.json`]();
                },
            })
            .directive("tooltip", Tooltip)
            .directive("animateonscroll", AnimateOnScroll)
            .mount(el);
    },
    progress: {
        color: "#4B5563",
        delay: 250,
        includeCSS: true,

        // Whether the NProgress spinner will be shown...
        showSpinner: false,
    },
});

<script setup>
import { ref, onMounted, inject } from 'vue';

// Layout setup
import Layout from '@/Layouts/Layout.vue' // Assurez-vous que le chemin d'importation est correct
defineOptions({
    layout: (h, page) => h(Layout, { largeLayout: true }, {
        default: () => page
    }),
});
const mainWidthClass = inject("mainWidthClass");
const fullWidthClass = inject("fullWidthClass");


// Import components
import Fact from "@/Components/Headings/Fact.vue"
import SubTitle from "@/Components/Headings/SubTitle.vue"
import GridListToggle from "@/Components/Feed/GridListToggle.vue";
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import SecondaryButton from '@/Components/Buttons/SecondaryButton.vue';
import Button from '@/Components/Buttons/Button.vue';
import Filter from "@/Components/Icons/Filter.vue";


// set up article layout animation
const articles_layout = ref("grid");
const show_layout_demo_image_list = articles_layout.value == "list" ? ref(true) : ref(false);
const updateLayout = (value) => {
    articles_layout.value = value;
    show_layout_demo_image_list.value = articles_layout.value == "list" ? true : false;
};


// Animation
import { animate, inView, timeline } from "motion";
import { slideUpAnim, vh2px } from "@/Animations/animations.js"
onMounted(() => {
    // Title sequence animation 
    const titleSequence = [
        ["#title", slideUpAnim, { duration: 1, at: 0.5 }],
        ["#subTitle", slideUpAnim, { duration: 1, at: "-0.5" }],
        [".highlited_animated > span", {
            width: ["0px", "100%"],
        }, { duration: 0.5, at: "-0.5" }],
        ["#seeArticlesButton", slideUpAnim, { duration: 1, at: "<" }],
        [".mockups", {
            transform: ["translateY(75px)", "translateY(0%)"],
            opacity: [0, 1]
        }, { duration: 1, at: "+0" }],
    ]
    timeline(titleSequence);

    // If less md: screen then add the other animations 
    if (window.innerWidth > 768) {
        // iPhone animation
        inView(".mockups>.odds", (info) => {
            animate(".mockups>.odds", {
                top: ["10vh", "0px"]
            }, { duration: 2 });
        }, { margin: vh2px(-10) })

        // Key messages animation
        document.querySelectorAll('.key-messages>*').forEach(elem => {
            elem.classList.add("opacity-0");
            inView(elem, (info) => {
                animate(elem, slideUpAnim, { duration: 1, easing: "ease-in-out" });
            }, { delay: 0.5 })
        });

        // Animation on filters demo
        inView("#filter-demo-button", (info) => {
            timeline([
                ["#filter-demo-heading", slideUpAnim, { duration: 0.5 }],
                ["#filter-demo-description", slideUpAnim, { duration: 1 }],
                ["#save-filter-demo-heading", slideUpAnim, { duration: 0.5, delay: 0.25 }],
                ["#save-filter-demo-description", slideUpAnim, { duration: 1 }],
                ["#filter-demo-docs-button", slideUpAnim, { duration: 0.5 }],

            ])

        }, { delay: 1 })

        // Animation on layout demo
        inView("#layout-demo-heading", (info) => {
            timeline([
                ["#layout-demo-heading", slideUpAnim, { duration: 1 }],
                ["#layout-demo-image", slideUpAnim, { duration: 1 }],
            ]);
        }, { delay: 1 })

        // Animation on read later demo
        inView("#read-later-demo-heading", (info) => {
            timeline([
                ["#read-later-demo-heading", slideUpAnim, { duration: 0.5 }],
                ["#read-later-demo-description", slideUpAnim, { duration: 1 }],
                ["#read-later-demo-docs-button", slideUpAnim, { duration: 0.5 }],

            ])

        }, { delay: 1 })

    }
})
</script>


<template>

    <Head :title="$t('About')" />

    <div class="flex flex-col overflow-y-hidden">
        <div :class="[mainWidthClass, ' h-[calc(100vh-3.5rem-24px-20vh)] pb-32 flex items-center']">
            <div>
                <H1 id="title" class="!text-6xl">Auto <i class="highlited_animated"><span></span> Novus</i></H1>

                <SubTitle id="subTitle" class="mt-4 text-surface-950/75 !text-3xl">Une
                    nouvelle façon de suivre
                    l’actualité
                    automobile</SubTitle>
                <div id="seeArticlesButton">
                    <Button :href="route('feed.index')"
                        class="border-2 border-primary-950 w-fit mt-8 hover:opacity-50">{{
                            $t("See last articles")
                        }}<i class="ml-2 pi pi-arrow-right">
                        </i></Button>
                </div>

            </div>
        </div>
        <div :class="[fullWidthClass, 'flex flex-col gap-y-36 md:gap-y-72 !px-0 mb-36']">
            <div
                class="mockups mb-24 md:mb-0 flex justify-center items-start gap-8 sm:gap-16 md:gap-24 [&>*]:w-48 [&>*]:sm:w-72 [&>*]:md:w-80 [&>*]:object-contain">
                <img src="/images/mockups/selection_bmw.png" class="iPhone-shadow odds relative top-[10vh]" alt="">
                <img src="/images/mockups/flux_list.png" class="iPhone-shadow" alt="">
                <img src="/images/mockups/flux_grille.png" class="iPhone-shadow odds relative top-[10vh]" alt="">
                <img src="/images/mockups/filtres.png" class="iPhone-shadow" alt="">
                <img src="/images/mockups/selections.png" class="iPhone-shadow odds relative top-[10vh]" alt="">
            </div>

            <div
                :class="[mainWidthClass, 'key-messages flex flex-col gap-y-24 md:gap-y-36 justify-center items-center text-center']">
                <Fact class="">
                    <span class="emphasize">Suivez toute l'actualité automobile</span> dans une interface simple et
                    fonctionnelle.
                </Fact>
                <Fact class=""><span class="emphasize">Gagnez du temps : </span>retrouvez tous vos articles à
                    un seul endroit.
                </Fact>
                <Fact class=""><span class="emphasize">Découvrez des articles de sources variées :
                    </span>presse automobile,
                    généraliste, économique et tech.</Fact>
                <Button :href="route('feed.index')" class="w-fit h-10 mt-8 align-middle bg-primary-950 text-white">{{
                    $t("See last articles")
                }}<i class="ml-2 pi pi-arrow-right">
                    </i></Button>

            </div>
            <div class="w-full bg-surface-50 py-32">
                <div
                    :class="[mainWidthClass, 'flex flex-col md:flex-row flex-wrap justify-between items-center md:items-stretch gap-32']">
                    <div class="max-w-md relative flex flex-col justify-evenly gap-12">
                        <div id="filter-demo-container">
                            <div id="filter-demo-heading" class="flex gap-4 items-center">
                                <PrimaryButton id="filter-demo-button">
                                    <Filter class="h-6 inline-block mr-2" />
                                    <span class="button-text">Filtrez</span>
                                </PrimaryButton>
                                <Fact id="filter-demo-title" class="relative">
                                    <span class="emphasize">les articles</span>
                                </Fact>
                            </div>
                            <SubTitle id="filter-demo-description" class="mt-5 !text-xl text-wrap">Affinez votre suivi
                                de l'actualité en
                                filtrant par
                                mots-clés, date ou
                                source</SubTitle>
                        </div>
                        <div>

                            <div id="save-filter-demo-heading" class="flex gap-4 items-center">
                                <SecondaryButton id="save-filter-demo-button" class="items-center relative z-1">
                                    Sauvegardez
                                </SecondaryButton>
                                <Fact id="save-filter-demo-title" class="relative">
                                    <span class="emphasize">vos filtres</span>
                                </Fact>
                            </div>
                            <SubTitle id="save-filter-demo-description" class="mt-5 !text-xl text-wrap">Sauvegardez vos
                                filtres préférés et
                                retrouvez les
                                dans votre
                                espace "Mes filtres"</SubTitle>
                        </div>
                        <Button id="filter-demo-docs-button" :href="route('docs')"
                            class="w-fit h-10 mt-8 align-middle bg-primary-950 text-white">Apprendre à gérer les
                            filtres<i class="ml-2 pi pi-arrow-right">
                            </i></Button>
                    </div>
                    <div class="relative w-72">
                        <img src="/images/mockups/filtres.png" class="iPhone-shadow">
                    </div>
                </div>
            </div>
            <div :class="[mainWidthClass, 'flex flex-col items-center gap-24']">
                <div id="layout-demo-heading" class="flex gap-4">
                    <GridListToggle :layout="articles_layout" class="grow-0"
                        @update:modelValue="(value) => updateLayout(value)" />
                    <Fact>
                        <span class="emphasize">Adaptez l'affichage</span>
                    </Fact>
                </div>
                <div id="layout-demo-image" class="w-72 relative">
                    <img src="/images/mockups/flux_grille.png" class="iPhone-shadow" alt="">
                    <Transition name="fade">
                        <img v-show="show_layout_demo_image_list" id="layout-demo-image-list"
                            src="/images/mockups/flux_list.png"
                            class="absolute top-0 transition-opacity duration-500 iPhone-shadow" alt="">
                    </Transition>
                </div>


            </div>
            <div class="w-full bg-surface-50 py-32">
                <div
                    :class="[mainWidthClass, 'flex flex-col md:flex-row flex-wrap justify-between items-center md:items-stretch gap-32']">
                    <div class="max-w-md relative flex flex-col justify-evenly gap-12">
                        <div id="read-later-demo-container">
                            <div id="read-later-demo-heading" class="flex gap-4 items-center">
                                <i
                                    class="pi pi-plus mx-1 h-[50px] w-[50px] content-center text-3xl bg-primary-950 text-white text-center rounded-lg shrink-0"></i>
                                <Fact id="read-later-demo-title" class="relative">
                                    <span class="emphasize">Sauvegardez les articles</span>
                                </Fact>
                            </div>
                            <SubTitle id="read-later-demo-description" class="mt-5 !text-xl text-wrap"> Si un article
                                vous
                                intéresse et que vous ne souhaitez pas le lire immédiatement, vous pouvez
                                l'ajouter à la
                                liste “À lire plus tard”
                            </SubTitle>
                        </div>
                        <Button id="read-later-demo-docs-button" :href="route('docs')"
                            class="w-fit h-10 mt-8 align-middle bg-primary-950 text-white">Apprendre à sauvegarder
                            un article<i class="ml-2 pi pi-arrow-right">
                            </i></Button>
                    </div>
                    <div class="relative w-72">
                        <img src="/images/mockups/filtres.png" class="iPhone-shadow">
                    </div>
                </div>
            </div>

            <div :class="[mainWidthClass, 'flex justify-center']">
                <a id="linkedin_button" href="https://www.linkedin.com/company/auto-novus" target="_blank"
                    rel="noopener noreferrer" class="flex gap-4 items-center">
                    <i class="pi pi-linkedin text-5xl text-[#0e76a8]"></i>
                    <Fact>
                        <span class="emphasize">Suivez-nous</span>
                    </Fact>
                </a>
            </div>
        </div>

    </div>

</template>

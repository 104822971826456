<script setup>
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
import Button from "@/Components/Buttons/Button.vue";
const toast = useToast();

const sevClass = (severity) => {
    switch (severity) {
        case 'success':
            return 'bg-green-500';
        case 'info':
            return 'bg-blue-500';
        case 'warn':
            return 'bg-orange-500';
        case 'error':
            return 'bg-red-500';
        default:
            return '';
    }
}

const sevIcon = (severity) => {
    switch (severity) {
        case 'success':
            return 'pi pi-check';
        case 'info':
            return 'pi pi-info';
        case 'warn':
            return 'pi pi-exclamation-triangle';
        case 'error':
            return 'pi pi-times';
        default:
            return '';
    }
}

</script>


<template>
    <Toast position="bottom-left" :pt="{ container: { class: ' !border-transparent' } }"
        :ptOptions="{ mergeProps: true }">
        <template #message="slotProps">
            <div class="flex flex-col items-start" style="flex: 1">
                <div class="flex gap-x-3">
                    <span
                        :class="['rounded-[100%] h-6 w-6 text-white text-center', sevClass(slotProps.message.severity)]">
                        <i :class="['pi text-xs', sevIcon(slotProps.message.severity)]"></i>
                    </span>
                    <H3>{{ slotProps.message.summary }}</H3>
                </div>
                <p class="mt-3">{{ slotProps.message.detail }}</p>
                <Button v-if="slotProps.message.buttonText" :href="slotProps.message.href"
                    :class="['mt-4 text-white', sevClass(slotProps.message.severity)]">
                    {{ slotProps.message.buttonText }}
                </Button>
            </div>
        </template>
    </Toast>
</template>
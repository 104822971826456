<script setup>
import Form from "@/Components/Form.vue";
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/Buttons/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import Password from 'primevue/password';

const props = defineProps({
    alreadyRegistered: {
        type: Boolean,
        default: true,
    },
});


const form = useForm({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('register'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>

    <form @submit.prevent="submit">
        <div>
            <InputLabel for="name" :value="$t('Name')" />

            <TextInput id="name" type="text" class="mt-1 block w-full" v-model="form.name" required autofocus
                autocomplete="name" />

            <InputError class="mt-2" :message="form.errors.name" />
        </div>

        <div class="mt-4">
            <InputLabel for="email" :value="$t('Email Address')" />

            <TextInput id="email" type="email" class="mt-1 block w-full" v-model="form.email" required
                autocomplete="username" />

            <InputError class="mt-2" :message="form.errors.email" />
        </div>

        <div class="mt-4">
            <InputLabel for="password" :value="$t('Password')" />

            <Password id="password" class="mt-1 block w-full !bg-transparent" inputClass="bg-transparent focus:!z-0"
                v-model="form.password" required autocomplete="new-password" toggleMask />

            <InputError class="mt-2" :message="form.errors.password" />
        </div>

        <div class="mt-4">
            <InputLabel for="password_confirmation" :value="$t('Confirm Password')" />

            <Password id="password_confirmation" class="mt-1 block w-full !bg-transparent"
                inputClass="bg-transparent focus:!z-0" v-model="form.password_confirmation" required
                autocomplete="new-password" toggleMask :feedback="false" />

            <InputError class="mt-2" :message="form.errors.password_confirmation" />
        </div>

        <div class="flex items-center justify-end mt-4">
            <Link v-if="alreadyRegistered" :href="route('login')"
                class="underline text-base text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {{ $t('Already registered?') }}
            </Link>

            <PrimaryButton class="ms-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                {{ $t('Register') }}
            </PrimaryButton>
        </div>
    </form>

</template>
